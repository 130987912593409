import React, { useCallback, useEffect, useRef, useState } from 'react';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useIds } from '@ninox/ninox-components/hooks/useId';
import { useMoveFocus } from '@ninox/ninox-components/hooks/useMoveFocus';
import { NavigationButton } from '@ninox/ninox-components/lib/NavigationButton';

import { track } from '../../tracking/MixPanel';
import type { NavRoute } from '../Nav';
import { NavContext } from '../Nav';
import { Container, Description, TabPanel, Tabs, Title } from './TabNav.styles';

const ids = ['selected', 'title', 'description', 'panel'] as const;

type Properties = {
    children?: React.ReactNode;
    routes: NavRoute[];
    top?: React.ReactNode;
    showDescriptions?: boolean;
    showTitles?: boolean;
};

export function TabNav({ children, routes, showDescriptions = true, showTitles = true, top }: Properties) {
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const location = useLocation();
    const { selected, title, description, panel } = useIds(ids);
    const movefocus = useMoveFocus(ref, selected.id, '[role="tab"]');
    const [currentRoute, setCurrentRoute] = useState(routes[0]);

    const keydown = useCallback(
        (e: React.KeyboardEvent) => {
            switch (e.code) {
                case 'ArrowLeft':
                    movefocus(false);
                    break;
                case 'ArrowRight':
                    movefocus(true);
                    break;
                case 'Home':
                    e.preventDefault();
                    movefocus('first');
                    break;
                case 'End':
                    e.preventDefault();
                    movefocus('last');
                    break;
            }
        },
        [movefocus]
    );

    useEffect(() => {
        // update currentRoute if routes are changing
        if (!ref.current) return;

        const link = ref.current.querySelector<HTMLAnchorElement>(`[aria-current]`);

        if (!link) return;

        const path = link.dataset.path || '';

        setCurrentRoute(routes.find((route) => route.value === path) || routes[0]);
    }, [routes, location.pathname]);

    const isTabsHidden = routes.length <= 1;

    return (
        <>
            <Container>
                {top}
                <Tabs ref={ref} hidden={isTabsHidden} role="tablist" tabIndex={-1} onKeyDown={keydown}>
                    {routes.map((route) => {
                        const isCurrent = currentRoute.value === route.value;

                        return (
                            <NavigationButton
                                key={route.value}
                                aria-controls={panel.id}
                                aria-disabled={route.disabled}
                                aria-hidden={!isCurrent}
                                aria-selected={isCurrent}
                                as={NavLink}
                                data-path={route.value}
                                end={route.end}
                                id={isCurrent ? selected.id : undefined}
                                kind="tab"
                                role="tab"
                                tabIndex={!isTabsHidden && isCurrent ? 0 : -1}
                                to={route.value}
                                onClick={preventDefault}
                                onFocus={(e) => {
                                    if (route.trackKey) {
                                        track(route.trackKey, 'Open');
                                    }

                                    e.currentTarget.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
                                    (e.currentTarget.style as React.CSSProperties).viewTransitionName = selected.id;

                                    setCurrentRoute(route);

                                    navigate(route.value, { unstable_viewTransition: true });
                                }}
                            >
                                {route.label}
                            </NavigationButton>
                        );
                    })}
                </Tabs>

                <Title hidden={!showTitles} id={title.id}>
                    {currentRoute.label}
                </Title>

                <Description hidden={!showDescriptions} id={description.id}>
                    {currentRoute.description}
                </Description>
            </Container>

            <TabPanel
                aria-describedby={description.id}
                aria-labelledby={title.id}
                id={panel.id}
                role="tabpanel"
                tabIndex={-1}
            >
                <NavContext.Provider value={currentRoute}>{children}</NavContext.Provider>
            </TabPanel>
        </>
    );
}

function preventDefault(e: React.MouseEvent | React.FocusEvent) {
    e.preventDefault();
}

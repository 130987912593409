export const signInUrl = (noRedirect = false) => {
    if (typeof window === 'undefined') return null;

    const basePath = process.env.LOGIN_PATH || window.location.origin;

    try {
        return new URL(`${basePath}${noRedirect ? '' : `?redirect=${window.location.href}`}`);
    } catch (error) {
        console.error('Invalid URL in signInUrl:', error);

        return new URL(window.location.origin);
    }
};

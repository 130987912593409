import React, { useCallback, useContext, useRef } from 'react';

import { useOpened } from '@ninox/ninox-components/hooks/useOpened';
import { Button } from '@ninox/ninox-components/lib/Button';
import { MaterialIcon } from '@ninox/ninox-components/lib/Icon';
import { Popup } from '@ninox/ninox-components/lib/Popup';
import { LocaleContext } from '@ninox/ninox-locales/react';
import { Size } from '@ninox/ninox-components/theme';
import { startViewTransition } from '@ninox/ninox-components/utils/startViewTransition';

import { PrivateClient } from '../../../api/private';
import { PublicClient } from '../../../api/public';
import { SessionContext } from '../../../session/session.context';
import { signInUrl } from '../../../utils/signInUrl';
import { Content, StyledAvatar } from './AppButton.styles';

type Properties = Omit<React.ComponentProps<typeof StyledAvatar>, 'src' | 'onClick'> & {
    isPublic?: boolean;
};

export const AppButton = ({ isPublic, ...properties }: Properties) => {
    const { user } = useContext(SessionContext);
    const locale = useContext(LocaleContext);
    const anchor = useRef<HTMLButtonElement>(null);
    const popup = useRef<HTMLDivElement>(null);
    const content = useRef<HTMLDivElement>(null);
    const [isOpened, setIsOpened] = useOpened(anchor, popup);
    const toggle = useCallback(() => setIsOpened((p) => !p), []);

    const handleLogout = useCallback(async () => {
        const api = isPublic ? PublicClient : PrivateClient;

        try {
            await api.logout();
        } catch (error) {
            console.warn(error);
        }

        startViewTransition(() => {
            const url = signInUrl();

            window.location.href = url.href;
        });
    }, []);

    return (
        <>
            <StyledAvatar
                fallback={<MaterialIcon icon="person" size={Size.sm} />}
                size={Size.m}
                {...properties}
                ref={anchor}
                aria-pressed={isOpened}
                src={user?.photo}
                onClick={toggle}
            />

            <Popup
                ref={popup}
                anchor={anchor}
                horizontalAlign="right"
                isOpened={isOpened}
                role="menu"
                verticalAlign="bottom"
                withTip
            >
                <Content ref={content}>
                    <Button attention emphasis="quaternary" icon="logout" onClick={handleLogout}>
                        {locale.logout}
                    </Button>
                </Content>
            </Popup>
        </>
    );
};
